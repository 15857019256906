import { render, staticRenderFns } from "./SearchProfile.vue?vue&type=template&id=9cb69032"
import script from "./SearchProfile.vue?vue&type=script&lang=js"
export * from "./SearchProfile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProfileIcons: require('/home/code/flirt-frontends/packages/twizie/components/ProfileIcons.vue').default})
