var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.nativeLink ? 'nuxtLink' : 'div',{tag:"component",staticClass:"search-profile",class:_vm.suggested ? 'suggested' : 'search',attrs:{"to":_vm.localePath({
      name: _vm.linkPage,
      params: {
        id: _vm.profile.id,
      },
    })},on:{"click":(e) => _vm.$emit('click', e)}},[(_vm.title)?_c('h6',{staticClass:"pt-2 profile-title"},[_c('b',[_vm._v(_vm._s(_vm.title))])]):_vm._e(),_vm._v(" "),(_vm.designVersion == 1 && _vm.profile.like_new)?_c('span',{staticClass:"badge badge-secondary new-like"},[_vm._v("\n    "+_vm._s(_vm.$t('new_like'))+"\n  ")]):_vm._e(),_vm._v(" "),(
      _vm.designVersion == 1 &&
      _vm.profile.main_image &&
      _vm.profile.main_image.thumb_filename &&
      _vm.profile.main_image.status == 'active'
    )?_c('div',{staticClass:"profile-image-aspect-ratio-container",style:('padding-bottom: ' +
      (_vm.profile.main_image.thumb_height / _vm.profile.main_image.thumb_width) *
        100 +
      '%')},[(_vm.profile.like_new)?_c('span',{staticClass:"badge badge-secondary new-like"},[_vm._v("\n      "+_vm._s(_vm.$t('new_like'))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.displayProfileLanguage)?_c('div',{staticClass:"profile-language"},[_c('span',{staticClass:"badge badge-language"},[_vm._v(" "+_vm._s(_vm.displayLanguage)+" ")])]):_vm._e(),_vm._v(" "),_c('img',{attrs:{"src":_vm.$store.state.settings.cdn_prefix + _vm.profile.main_image.thumb_filename}}),_vm._v(" "),_c('profile-icons',{attrs:{"profile":_vm.profile}})],1):_vm._e(),_vm._v(" "),(
      _vm.designVersion == 2 &&
      _vm.profile.main_image &&
      _vm.profile.main_image.thumb_filename &&
      _vm.profile.main_image.status == 'active'
    )?_c('div',{staticClass:"profile-image-aspect-ratio-container"},[(_vm.profile.like_new)?_c('span',{staticClass:"badge badge-secondary new-like"},[_vm._v("\n      "+_vm._s(_vm.$t('new_like'))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.displayProfileLanguage)?_c('div',{staticClass:"profile-language"},[_c('span',{staticClass:"badge badge-language"},[_vm._v(" "+_vm._s(_vm.displayLanguage)+" ")])]):_vm._e(),_vm._v(" "),_c('img',{attrs:{"src":_vm.$store.state.settings.cdn_prefix + _vm.profile.main_image.thumb_filename}})]):_vm._e(),_vm._v(" "),(
      _vm.designVersion == 1 &&
      (!_vm.profile.main_image ||
        !_vm.profile.main_image.thumb_filename ||
        !_vm.profile.main_image.status == 'active')
    )?_c('div',{staticClass:"no-image"},[_c('font-awesome-icon',{staticClass:"no-image-icon",attrs:{"size":"4x","icon":['far', 'user']}})],1):_vm._e(),_vm._v(" "),(
      _vm.designVersion == 2 &&
      (!_vm.profile.main_image ||
        !_vm.profile.main_image.thumb_filename ||
        !_vm.profile.main_image.status == 'active')
    )?_c('div',{staticClass:"profile-image-aspect-ratio-container"},[_c('img',{attrs:{"src":require("assets/noimage.png")}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"profile-info",class:_vm.designVersion == 2 ? 'px-0' : ''},[(_vm.displayName)?_c('h6',{staticClass:"pt-2 profile-name"},[_c('b',[_vm._v(_vm._s(_vm.profile.name)),(_vm.designVersion == 2)?_c('span',[_vm._v(", "+_vm._s(_vm.profile.age))]):_vm._e()])]):_vm._e(),_vm._v(" "),_vm._l((_vm.profileDetailFields),function(field){return _c('div',{key:field,class:'search-profile-details profile-' + field},[(_vm.designVersion == 2 && !_vm.suggested)?_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['far', 'location-pin']}}):_vm._e(),_vm._v(" "),(field === 'country')?[_vm._v("\n        "+_vm._s(_vm.countryName(_vm.profile[field]))+"\n      ")]:(field === 'countryregion')?[_vm._v("\n        "+_vm._s(_vm.regionName)+"\n      ")]:[_vm._v(" "+_vm._s(_vm.profile[field]))],_vm._v(" "),(_vm.designVersion === 2)?_c('profile-icons',{attrs:{"only-fictional":true,"profile":_vm.profile}}):_vm._e()],2)})],2),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }